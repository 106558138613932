var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[{ 'full-width': _vm.fullWidth }]},[_c('label',{directives:[{name:"show",rawName:"v-show",value:(_vm.customLabel),expression:"customLabel"}],staticClass:"pb-0 mb-0"},[_vm._v(_vm._s(_vm.customLabel))]),_c('div',[_c(_vm.componentType,_vm._g(_vm._b({tag:"component",class:[
        'custom-text-field',
        {
          textarea: _vm.textarea,
          flat: _vm.flat,
          [`icon-pos-${_vm.iconPosition}`]: !!_vm.icon
        },
        _vm.cssClasses
      ],attrs:{"outlined":"","append-icon":_vm.icon,"type":_vm.chooseType()},on:{"input":_vm.onInput},scopedSlots:_vm._u([(_vm.customType==='password')?{key:"append",fn:function(){return [_c('v-icon',{on:{"click":_vm.onShowPassword}},[_vm._v(_vm._s(_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye'))])]},proxy:true}:null],null,true)},'component',_vm.$attrs,false),_vm.click ? { click: _vm.click } : {}))],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }