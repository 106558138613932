<template>
  <div :class="[{ 'full-width': fullWidth }]">
    <label v-show="customLabel" class="pb-0 mb-0">{{ customLabel }}</label>
    <div>
      <component
        :is="componentType"
        v-bind="$attrs"
        v-on="click ? { click } : {}"
        @input="onInput"
        :class="[
          'custom-text-field',
          {
            textarea,
            flat,
            [`icon-pos-${iconPosition}`]: !!icon
          },
          cssClasses
        ]"
        outlined
        :append-icon="icon"
        :type="chooseType()"
      >
        <template v-if="customType==='password'" v-slot:append>
          <v-icon @click="onShowPassword">{{
            showPassword ? 'mdi-eye-off' : 'mdi-eye'
          }}</v-icon>
        </template>
      </component>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomTextField',
  data () {
    return {
      showPassword: false
    };
  },
  props: {
    flat: {
      type: Boolean,
      default: false
    },
    textarea: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    iconPosition: {
      type: String,
      default: 'prepend'
    },
    cssClasses: {
      type: String,
      default: ''
    },
    click: {
      type: Function
    },
    customType: {
      type: String,
      default: 'text'
    },
    customLabel: {
      type: String,
      default: null
    }
  },
  computed: {
    componentType () {
      return this.textarea ? 'v-textarea' : 'v-text-field';
    }
  },
  methods: {
    onInput (value) {
      this.internalValue = value;
      this.$emit('update:modelValue', value);
      this.$emit('input', value);
    },
    onShowPassword () {
      this.showPassword = !this.showPassword;
    },
    chooseType () {
      if (this.customType === 'password') {
        return this.showPassword ? 'text' : 'password';
      }
      return this.customType;
    }
  }
};
</script>

<style scoped>
.full-width {
  width: 100%;
}

.custom-text-field.textarea ::v-deep .v-text-field__slot textarea {
  padding-bottom: 0.5rem;
}

.custom-text-field ::v-deep .v-input__control {
  padding: 0 !important;

}

.custom-text-field ::v-deep .v-input__slot {
  border-radius: 16px !important;
  padding: 0 16px !important;

}

.custom-text-field ::v-deep .v-text-field__slot input{
  color:#212833 !important ;
  padding: 0 !important;
}

.custom-text-field ::v-deep .v-input__slot fieldset{
  top:5px !important;
  bottom: 5px !important;
  border-color: #606A76 !important;
  background-color: white !important;
}

.custom-text-field ::v-deep .v-input__slot{
  background-color: transparent !important;
}

.custom-text-field.icon-pos-prepend >>> .v-text-field__slot {
  order: 2;
}

.custom-text-field.icon-pos-prepend >>> .v-input__append-inner{
  padding-left: 0;
  padding-right: 4px;
}

.custom-text-field.flat ::v-deep .v-input__slot fieldset{
  border-color: #3F44A60A !important;
  background-color: #F5F7FA !important;
}

</style>
